.reading-progress-bar {
  --progress: 0;
  height: var(--progress-h, 2px);
  width: var(--progress);
  background-color: var(--bg-progress, var(--#{$prefix}info));
  z-index: 150;
  position: fixed;
  
  [data-theme="dark"] & {
    background-color: var(--bg-progress-dark, var(--#{$prefix}info-dark));
  }
}
